<template>
  <div class="px-12 pt-8 pb-12">
    <v-spacer></v-spacer>
    <template v-if="apierror.status == 'error'">
      <div v-for="msg of apierror.messages" :key="msg">
        <v-row class="ml-6 mb-3 ma-3">
          <span style="color: red">* {{ msg }} </span>
        </v-row>
      </div>
    </template>
    <v-spacer></v-spacer>
    <v-row class="mb-3">
      <v-col cols="12" md="2" sm="3">
        <v-autocomplete
          v-model="lc_nameFilter"
          :items="lc_names"
          placeholder="カテゴリー名"
          outlined
          clearable
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="2" sm="3">
        <v-autocomplete
          v-model="lc_codeFilter"
          :items="lc_codes"
          placeholder="カテゴリー値"
          outlined
          clearable
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" xl="3" lg="4" md="5" sm="6">
        <div class="d-flex justify-end">
          <v-btn class="mr-2" color="primary" elevation="0" @click.stop="openForm">
            <v-icon size="17" class="me-1">
              {{ icons.mdiPlus }}
            </v-icon>
            <span>新規</span>
          </v-btn>
          <v-btn color="secondary" outlined elevation="0" @click.stop="refreshList">
            <span>リーロード</span>
          </v-btn>
        </div>
      </v-col>
      <v-dialog v-model="dialog" persistent max-width="600px">
        <div>
          <v-card>
            <v-card-title>カテゴリー新規</v-card-title>
            <div class="mx-5 mt-5 mb-10">
              <v-row align="center">
                <v-col cols="3">
                  <div>
                    <span>カテゴリー値</span>
                  </div>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="Form.lc_code"
                    placeholder="カテゴリー値"
                    hide-details="auto"
                    clearable
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="3">
                  <div>
                    <span>カテゴリー名</span>
                  </div>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="Form.lc_name"
                    placeholder="カテゴリー名"
                    hide-details="auto"
                    clearable
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" class="mr-2" @click="submit(Form)">
                <span>OK</span>
              </v-btn>
              <v-btn color="secondary" outlined @click="dialog = false">
                <span>キャンセル</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-dialog>
    </v-row>
    <v-row align="center">
      <v-col cols="12">
        <v-data-table
          ref="LargeClasses"
          class="class-on-data-table elevation-1"
          :headers="headers"
          :items="List"
          fixed-header
          :items-per-page="-1"
        >
          <template v-slot:[`item.lc_name`]="{ item }">
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="11" md="10" lg="9" xl="8">
                <v-text-field
                  v-if="item.readonly"
                  v-model="item.lc_name"
                  placeholder="カテゴリー名"
                  hide-details="auto"
                  :readonly="item.readonly"
                  outlined
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="!item.readonly"
                  v-model="Form.lc_name"
                  placeholder="カテゴリー名"
                  hide-details="auto"
                  :readonly="Form.readonly"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </template>
          <template v-slot:[`item.lc_code`]="{ item }">
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="8" md="7" lg="6" xl="5">
                <v-text-field
                  v-if="item.readonly"
                  v-model="item.lc_code"
                  placeholder="カテゴリー値"
                  hide-details="auto"
                  readonly
                  outlined
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="!item.readonly"
                  v-model="Form.lc_code"
                  placeholder="カテゴリー値"
                  hide-details="auto"
                  readonly
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon v-if="item.readonly" class="mr-2" @click="doSelect(item)">
              {{ icons.mdiPencil }}
            </v-icon>
            <v-icon v-if="!item.readonly" class="mr-2" :loading="submitStatus" @click="doEdit(item)">
              {{ icons.mdiCheckBold }}
            </v-icon>
            <v-icon v-if="item.readonly" class="mr-2" @click="deleteLargeClass(item)">
              {{ icons.mdiDelete }}
            </v-icon>
            <v-icon v-if="!item.readonly" class="mr-2" @click="cancelSelect(item)">
              {{ icons.mdiCloseThick }}
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import { mdiPlus, mdiDelete, mdiCloseThick, mdiPencil, mdiCheckBold } from '@mdi/js'
import { mapState, mapActions } from 'vuex'
import Sortable from 'sortablejs'
/* eslint-disable */

export default {
  data: () => ({
    dialog: false,
    submitStatus: false,
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    lc_nameFilter: '',
    lc_codeFilter: '',
    Form: {
      lc_name: '',
      lc_code: '',
    },
    List: [],
    lc_names: [],
    lc_codes: [],
    arrBfo: [],
    icons: {
      mdiPlus,
      mdiDelete,
      mdiCloseThick,
      mdiPencil,
      mdiCheckBold,
    },
    headers: [
      {
        text: 'No.',
        value: 'id',
        align: 'left',
        width: '8%',
        sortable: false,
        fixed: true,
      },
      {
        text: 'カテゴリー名',
        value: 'lc_name',
        width: '40%',
        align: 'center',
        sortable: false,
        fixed: true,
      },
      {
        text: 'カテゴリー値',
        value: 'lc_code',
        width: '38%',
        align: 'center',
        sortable: false,
        fixed: false,
      },
      {
        text: '　',
        value: 'actions',
        width: '14%',
        align: 'right',
        sortable: false,
        fixed: true,
      },
    ],
  }),
  watch: {
    lc_nameFilter() {
      this.doFilter()
    },
    lc_codeFilter() {
      this.doFilter()
    },
  },
  computed: {
    ...mapState('largeclassStore', ['largeclassList', 'selectedLargeClass']),
  },
  created() {
    this.refreshList()
  },
  mounted() {
    this.sortLargeClasses()
  },
  methods: {
    ...mapActions('largeclassStore', ['loadLargeClassList', 'createLargeClass', 'loadLargeClass', 'editLargeClass']),

    refreshList() {
      this.loadLargeClassList().then(() => {
        console.log('loadLargeClassList', this.largeclassList)
        ;(this.List = []),
          this.largeclassList.map((item, index) => {
            this.List.push(Object.assign({}, item, { readonly: true }))
          })
        console.log('List', this.List)
        this.arrBfo = this.List
        this.lc_names = this.List.map(item => item.lc_name)
        console.log('lc_names', this.lc_names)
        this.lc_codes = this.List.map(item => item.lc_code)
        console.log('lc_codes', this.lc_codes)
      })
    },
    doSelect(item) {
      this.loadLargeClass(item.id).then(() => {
        console.log('selectedLargeClass', this.selectedLargeClass)
        this.Form = this.selectedLargeClass
        console.log('Form', this.Form)
        item.readonly = !item.readonly
      })
    },
    cancelSelect(item) {
      item.readonly = !item.readonly
      ;(this.Form = {
        lc_name: '',
        lc_code: '',
      }),
        console.log('Form', this.Form)
    },
    doEdit() {
      if (!this.$invalid) {
        this.submitStatus = true
        console.log('Form', this.Form)
        this.editLargeClass(this.Form)
          .then(() => {
            this.submitStatus = false
            this.refreshList()
          })
          .catch(error => {
            this.submitStatus = false
            this.apierror.status = error.response.data.status
            this.apierror.code = error.response.data.code
            this.apierror.messages.push(error.response.data.message)
          })
      } else {
        console.log('error submit!!')
      }
    },
    deleteLargeClass(item) {
      this.List.splice(this.List.indexOf(item), 1)

      // const id = []
      this.List.forEach((v, i) => {
        this.List[i].id = i + 1
        this.List[i].detail_id = i + 1
      })
    },
    doFilter() {
      let filterResult = this.arrBfo

      if (this.lc_nameFilter !== null && this.lc_nameFilter !== '') {
        filterResult = filterResult.filter(element => element.lc_name === this.lc_nameFilter)
      }

      if (this.lc_codeFilter !== null && this.lc_codeFilter !== '') {
        filterResult = filterResult.filter(element => element.lc_code === this.lc_codeFilter)
      }

      this.List = filterResult
    },
    sortLargeClasses() {
      const el = this.$refs.LargeClasses.$el.querySelectorAll('.v-data-table__wrapper > table> tbody')[0]
      Sortable.create(el, {
        ghostClass: 'sortable-ghost',
        sort: true,
        animation: 150,
        group: {
          name: 'LargeClass',
          pull: false,
          put: false,
        },
        setData(dataTransfer, dragEl) {
          dataTransfer.setData('Text', dragEl.textContent)
        },
        onEnd: evt => {
          const item = this.largeclassList[evt.oldIndex]

          this.largeclassList.splice(evt.oldIndex, 1)
          this.largeclassList.splice(evt.newIndex, 0, item)

          const orderLargeClasses = []
          this.largeclassList.forEach((v, i) => {
            orderLargeClasses[i] = v
            orderLargeClasses[i].detail_id = i + 1
          })
        },
      })
    },
    openForm() {
      this.dialog = true
    },
    submit(Form) {
      this.createLargeClass(Form)
        .then(() => {
          console.log('form:', this.Form)
          this.submitStatus = false
          this.dialog = false
          this.refreshList()
        })
        .catch(error => {
          this.submitStatus = false
          this.apierror.status = error.response.data.status
          this.apierror.code = error.response.data.code
          this.apierror.messages.push(error.response.data.message)

          console.log('apierror', this.apierror)
        })
    },
    // goBack(item) {
    //   this.dialog = false
    //   const Obj = { ...item }
    //   Obj.id = this.largeclassList.length
    //   Obj.detail_id = this.largeclassList.length + 1

    //   if (this.largeclassList.length >= 1) {
    //     const lastRow = this.largeclassList.length - 1
    //     Obj.lc_name = this.largeclassList[lastRow].mc_name
    //     Obj.lc_code = this.largeclassList[lastRow].mc_code
    //   } else {
    //     Obj.lc_name = ''
    //     Obj.lc_code = ''
    //   }
    //   this.largeclassList.push(Obj)
    // },
  },
}
</script>
