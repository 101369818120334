<template>
  <div class="px-12 pt-8 pb-12">
    <v-spacer></v-spacer>
    <template v-if="apierror.status == 'error'">
      <div v-for="msg of apierror.messages" :key="msg">
        <v-row class="ml-6 mb-3 ma-3">
          <span style="color: red">* {{ msg }} </span>
        </v-row>
      </div>
    </template>
    <v-spacer></v-spacer>
    <v-row class="mb-3">
      <v-col cols="12" lg="2" sm="3">
        <v-autocomplete
          v-model="lc_codeFilter"
          :items="lc_codes"
          placeholder="カテゴリー１"
          outlined
          clearable
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="2" sm="3">
        <v-autocomplete
          v-model="mc_codeFilter"
          :items="mc_codes"
          placeholder="カテゴリー２"
          outlined
          clearable
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="2" sm="3">
        <v-autocomplete
          v-model="sc_codeFilter"
          :items="sc_codes"
          placeholder="カテゴリー値"
          outlined
          clearable
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="2" sm="3">
        <v-autocomplete
          v-model="sc_nameFilter"
          :items="sc_names"
          placeholder="カテゴリー名"
          outlined
          clearable
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" xl="3" lg="2" md="12" sm="12">
        <div class="d-flex justify-end">
          <v-btn class="mr-2" color="primary" elevation="0" @click.stop="openForm">
            <v-icon size="17" class="me-1">
              {{ icons.mdiPlus }}
            </v-icon>
            <span>新規</span>
          </v-btn>
          <v-btn color="secondary" outlined elevation="0" @click.stop="refreshList">
            <span>リーロード</span>
          </v-btn>
        </div>
      </v-col>
      <v-dialog v-model="dialog" persistent max-width="600px">
        <div>
          <v-card>
            <v-card-title>カテゴリー新規</v-card-title>
            <div class="mx-5 mt-5 mb-10">
              <v-row align="center">
                <v-col cols="3">
                  <div>
                    <span>カテゴリー１</span>
                  </div>
                </v-col>
                <v-col>
                  <v-autocomplete
                    v-model="Form.lc_code"
                    :items="lc_codes"
                    placeholder="カテゴリー１"
                    outlined
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="3">
                  <div>
                    <span>カテゴリー２</span>
                  </div>
                </v-col>
                <v-col>
                  <v-autocomplete
                    v-model="Form.mc_code"
                    :items="mc_codes"
                    placeholder="カテゴリー２"
                    outlined
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="3">
                  <div>
                    <span>カテゴリー値</span>
                  </div>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="Form.sc_code"
                    placeholder="カテゴリー値"
                    hide-details="auto"
                    clearable
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="3">
                  <div>
                    <span>カテゴリー名</span>
                  </div>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="Form.sc_name"
                    placeholder="カテゴリー名"
                    hide-details="auto"
                    clearable
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" class="mr-2" @click="submit(Form)">
                <span>OK</span>
              </v-btn>
              <v-btn color="secondary" outlined @click="dialog = false">
                <span>キャンセル</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-dialog>
    </v-row>
    <v-row align="center">
      <v-col cols="12">
        <v-data-table
          ref="SmallClasses"
          class="class-on-data-table elevation-1"
          :headers="headers"
          :items="List"
          fixed-header
          :items-per-page="-1"
        >
          <template v-slot:[`item.sc_name`]="{ item }">
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="11" md="10" lg="9" xl="8">
                <v-text-field
                  v-if="item.readonly"
                  v-model="item.sc_name"
                  placeholder="カテゴリー名"
                  hide-details="auto"
                  :readonly="item.readonly"
                  outlined
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="!item.readonly"
                  v-model="Form.sc_name"
                  placeholder="カテゴリー名"
                  hide-details="auto"
                  :readonly="Form.readonly"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </template>
          <template v-slot:[`item.sc_code`]="{ item }">
            <v-text-field
              v-if="item.readonly"
              v-model="item.sc_code"
              placeholder="カテゴリー値"
              hide-details="auto"
              readonly
              outlined
              dense
            >
            </v-text-field>
            <v-text-field
              v-if="!item.readonly"
              v-model="Form.sc_code"
              placeholder="カテゴリー値"
              hide-details="auto"
              readonly
              outlined
              dense
            >
            </v-text-field>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon class="mr-2" v-if="item.readonly" @click="doSelect(item)">
              {{ icons.mdiPencil }}
            </v-icon>
            <v-icon class="mr-2" v-if="!item.readonly" :loading="submitStatus" @click="doEdit(item)">
              {{ icons.mdiCheckBold }}
            </v-icon>
            <v-icon class="mr-2" v-if="item.readonly" @click="deleteSmallClass(item)">
              {{ icons.mdiDelete }}
            </v-icon>
            <v-icon class="mr-2" v-if="!item.readonly" @click="cancelSelect(item)">
              {{ icons.mdiCloseThick }}
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import { mdiPlus, mdiDelete, mdiCloseThick, mdiPencil, mdiCheckBold } from '@mdi/js'
import { mapState, mapActions } from 'vuex'
import Sortable from 'sortablejs'
/* eslint-disable */

export default {
  data: () => ({
    dialog: false,
    submitStatus: false,
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    lc_codeFilter: '',
    mc_codeFilter: '',
    sc_nameFilter: '',
    sc_codeFilter: '',
    Form: {
      lc_code: '',
      mc_code: '',
      sc_name: '',
      sc_code: '',
    },
    List: [],
    lc_codes: [],
    mc_codes: [],
    sc_names: [],
    sc_codes: [],
    arrBfo: [],
    icons: {
      mdiPlus,
      mdiDelete,
      mdiCloseThick,
      mdiPencil,
      mdiCheckBold,
    },
    headers: [
      {
        text: 'No.',
        value: 'id',
        align: 'left',
        width: '8%',
        sortable: false,
        fixed: true,
      },
      {
        text: 'カテゴリー１',
        value: 'lc_code',
        width: '8%',
        align: 'left',
        sortable: false,
        fixed: true,
      },
      {
        text: 'カテゴリー２',
        value: 'mc_code',
        width: '8%',
        align: 'left',
        sortable: false,
        fixed: true,
      },
      {
        text: 'カテゴリー値',
        value: 'sc_code',
        width: '15%',
        align: 'center',
        sortable: false,
        fixed: false,
      },
      {
        text: 'カテゴリー名',
        value: 'sc_name',
        width: '26%',
        align: 'center',
        sortable: false,
        fixed: true,
      },
      {
        text: '　',
        value: 'actions',
        width: '14%',
        align: 'right',
        sortable: false,
        fixed: true,
      },
    ],
  }),
  watch: {
    lc_codeFilter() {
      this.doFilter()
    },
    mc_codeFilter() {
      this.doFilter()
    },
    sc_nameFilter() {
      this.doFilter()
    },
    sc_codeFilter() {
      this.doFilter()
    },
  },
  computed: {
    ...mapState('smallclassStore', ['smallclassList', 'selectedSmallClass']),
  },
  created() {
    this.refreshList()
  },
  mounted() {
    this.sortSmallClasses()
  },
  methods: {
    ...mapActions('smallclassStore', ['loadSmallClassList', 'createSmallClass', 'loadSmallClass', 'editSmallClass']),

    refreshList() {
      this.loadSmallClassList().then(() => {
        console.log('loadSmallClassList', this.smallclassList)
        ;(this.List = []),
          this.smallclassList.map((item, index) => {
            this.List.push(Object.assign({}, item, { readonly: true }))
          })
        console.log('List', this.List)
        this.arrBfo = this.List
        this.lc_codes = this.List.map(item => item.lc_code)
        console.log('lc_codes', this.lc_codes)
        this.mc_codes = this.List.map(item => item.mc_code)
        console.log('mc_codes', this.mc_codes)
        this.sc_codes = this.List.map(item => item.sc_code)
        console.log('sc_codes', this.sc_codes)
        this.sc_names = this.List.map(item => item.sc_name)
        console.log('sc_names', this.sc_names)
      })
    },
    doSelect(item) {
      this.loadSmallClass(item.id).then(() => {
        console.log('selectedSmallClass', this.selectedSmallClass)
        this.Form = this.selectedSmallClass
        console.log('Form', this.Form)
        item.readonly = !item.readonly
      })
    },
    cancelSelect(item) {
      item.readonly = !item.readonly
      ;(this.Form = {
        lc_code: '',
        mc_code: '',
        sc_name: '',
        sc_code: '',
      }),
        console.log('Form', this.Form)
    },
    doEdit() {
      if (!this.$invalid) {
        this.submitStatus = true
        console.log('Form', this.Form)
        this.editSmallClass(this.Form)
          .then(() => {
            this.submitStatus = false
            this.refreshList()
          })
          .catch(error => {
            this.submitStatus = false
            this.apierror.status = error.response.data.status
            this.apierror.code = error.response.data.code
            this.apierror.messages.push(error.response.data.message)
          })
      } else {
        console.log('error submit!!')
      }
    },
    deleteSmallClass(item) {
      this.List.splice(this.List.indexOf(item), 1)

      // const id = []
      this.List.forEach((v, i) => {
        this.List[i].id = i + 1
        this.List[i].detail_id = i + 1
      })
    },
    doFilter() {
      let filterResult = this.arrBfo

      if (this.lc_codeFilter !== null && this.lc_codeFilter !== '') {
        filterResult = filterResult.filter(element => element.lc_code === this.lc_codeFilter)
      }

      if (this.mc_codeFilter !== null && this.mc_codeFilter !== '') {
        filterResult = filterResult.filter(element => element.mc_code === this.mc_codeFilter)
      }

      if (this.sc_codeFilter !== null && this.sc_codeFilter !== '') {
        filterResult = filterResult.filter(element => element.sc_code === this.sc_codeFilter)
      }

      if (this.sc_nameFilter !== null && this.sc_nameFilter !== '') {
        filterResult = filterResult.filter(element => element.sc_name === this.sc_nameFilter)
      }

      this.List = filterResult
    },
    sortSmallClasses() {
      const el = this.$refs.SmallClasses.$el.querySelectorAll('.v-data-table__wrapper > table> tbody')[0]
      Sortable.create(el, {
        ghostClass: 'sortable-ghost',
        sort: true,
        animation: 150,
        group: {
          name: 'SmallClass',
          pull: false,
          put: false,
        },
        setData(dataTransfer, dragEl) {
          dataTransfer.setData('Text', dragEl.textContent)
        },
        onEnd: evt => {
          const item = this.smallclassList[evt.oldIndex]

          this.smallclassList.splice(evt.oldIndex, 1)
          this.smallclassList.splice(evt.newIndex, 0, item)

          const orderSmallClasses = []
          this.smallclassList.forEach((v, i) => {
            orderSmallClasses[i] = v
            orderSmallClasses[i].detail_id = i + 1
          })
        },
      })
    },
    openForm() {
      this.dialog = true
    },
    submit(Form) {
      this.createSmallClass(Form)
        .then(() => {
          console.log('form:', this.Form)
          this.submitStatus = false
          this.dialog = false
          this.refreshList()
        })
        .catch(error => {
          this.submitStatus = false
          this.apierror.status = error.response.data.status
          this.apierror.code = error.response.data.code
          this.apierror.messages.push(error.response.data.message)

          console.log('apierror', this.apierror)
        })
    },
  },
}
</script>
