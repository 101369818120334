<template>
  <div id="category">
    <v-card>
      <v-card-title>カテゴリー</v-card-title>
      <Nav></Nav>
    </v-card>
  </div>
</template>
<script>
/* eslint-disable */
import {
} from '@mdi/js'
import Nav from './Nav.vue'
/* eslint-disable */

export default {
  components: {
    Nav,
  },
  data: () => ({
    icons: {
    },
  }),
  computed: {
  },
  watch: {
  },
  methods: {
  },
}
</script>
<style scoped>
</style>
