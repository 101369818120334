<template>
  <div>
    <v-tabs>
      <v-tab href="#Category1">
        カテゴリー１
      </v-tab>
      <v-tab-item value="Category1">
        <Category1></Category1>
      </v-tab-item>

      <v-tab href="#Category2">
        カテゴリー２
      </v-tab>
      <v-tab-item value="Category2">
        <Category2></Category2>
      </v-tab-item>

      <v-tab href="#Category3">
        カテゴリー３
      </v-tab>
      <v-tab-item value="Category3">
        <Category3></Category3>
      </v-tab-item>
    </v-tabs>
  </div>
</template>
<script>
/* eslint-disable */
import Category1 from "./Category1.vue";
import Category2 from "./Category2.vue";
import Category3 from "./Category3.vue";
/* eslint-disable */

  export default {
    data: () => ({
      tab: null,
      items: [
        { tab: 'カテゴリー1'},
        { tab: 'カテゴリー2'},
        { tab: 'カテゴリー3'},
      ],
    }),
    components: {
      Category1,
      Category2,
      Category3,
    },
  }
</script>